@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(//fonts.googleapis.com/css?family=Lato:300|Roboto:400,500,300,300italic,900,900italic,700|Cutive);

// Core variables
@import "variables.less";

.nopadding {
	margin: 0;
	padding: 0;
}


// 5 COLUMNS LAYOUT CLASSES
//

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-15 {
    width: 20%;
    float: left;
}
@media (min-width: 768px) {
	.col-sm-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 992px) {
    .col-md-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 1200px) {
    .col-lg-15 {
        width: 20%;
        float: left;
    }
}

// END 5 COLUMNS LAYOUT CLASSES
//
/*========================================================
                     HEADER  Section
=========================================================*/
header {
  background: #ffffff;
  position: relative;
}
header:before,
header:after {
  content: " ";
  display: table;
}
header:after {
  clear: both;
}
header:before,
header:after {
  content: " ";
  display: table;
}
header:after {
  clear: both;
}
header:before,
header:after {
  content: " ";
  display: table;
}
header:after {
  clear: both;
}
header:before,
header:after {
  content: " ";
  display: table;
}
header:after {
  clear: both;
}
header .inline-list {
  float: left;
}
header .info-list {
  float: right;
}

/*========================================================
                     HEADER  Section	END
=========================================================*/


/*========================================================
                     NAVBAR  Section
=========================================================*/

.menupp {
	padding: 0px;
}

@media (max-width: 767px) {
  .navbar {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-nav {
    margin: 0;
    font: 400 15px/31px 'Roboto', sans-serif;
    padding-top: 10px;
    padding-bottom: 20px;
    word-spacing: -0.4px;
  }
  .navbar-nav > li {
    float: left;
  }
  .navbar-nav > li > a {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    padding: 10px 9px;
    display: block;
    text-transform: uppercase;
    line-height: inherit;
  }
}
@media (min-width: 768px) and (max-width: 1500px) {
  .navbar-nav > li > a {
    padding: 16px 5px;
    font-size: 13px;
  }
}
@media (min-width: 768px) and (max-width: 767px) {
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    font-style: italic;
  }
}
@media (max-width: 1500px) {
  .navbar-nav {
    /*padding-top: 29px;*/
    padding-bottom: 18px;
  }
}
@media (min-width: 768px) {
  .navbar-left {
    float: left;
  }
  .navbar-right {
    float: right;
    margin-right: 150px;
  }
  .navbar-right ~ .navbar-right {
    margin-right: 0;
  }
}

.navbar-default {
    background-color: @navbar-default-bg;
    border-color: @navbar-default-border;
}

.navbar {
	margin-bottom: 5px;
	ul li {
		list-style-type: none;
	}
	a:hover {
		text-decoration: none;	
	}
}
/*========================================================
                     NAVBAR  Section	END
=========================================================*/


/*========================================================
                     ACCUEIL  Page
=========================================================*/

/******************* BLOC ACcueil Présentation (ancien historique)  ***************/

.presentation-accueil {
	font-size: 14px;
	line-height: 1.4em;
	text-align: justify;
}
.p-left {	
	@media(min-width: 992px) {
		padding-left: 0px;
	}
	@media(max-width: 992px) {
		padding-left: 0px;
		padding-right: 0px;		
	}
}
.p-right {
	@media(min-width: 992px) {			
		padding-right: 0px;
	}		
	@media(max-width: 992px) {
		padding-left: 0px;
		padding-right: 0px;		
	}
}

/******************* BLOC 28ans et bouton  ***************/

.bloc-gauche-bet {
	padding: 0px 0px 0px 0px;
	text-align: left;
	background: url(../images/fond-bandeau-accueil.png) left top no-repeat;
}

.bloc-droite-28ans {
	padding-top: 0px;
	padding-right: 0px;
	@media(max-width: 992px) {
		padding-top: 0px;
	}	
}

.logo-28ans {
	padding-top: 0px;
	margin-top:5px;
	padding-bottom: 10px;
	@media(max-width: 992px) {
		padding-top: 0px;
		margin-top: 0px;
		margin-bottom: 15px;
	}
}

.bordure-gauche {
	border-left: 1px solid grey;
	margin : 5px 0px;
	@media(max-width: 992px) {
		border: none;
	}
}

.marge-en-haut{
	margin-top: 0px;
	@media(max-width: 992px) {
		margin-top: 0px;
	}
}
.marge-en-bas{
	margin-bottom: -30px;
	@media(max-width: 992px) {
		margin-bottom: 0px;
	}
}

/******************* FIN BLOC 28ans et bouton  ***********************/

/******************* BLOC EXPERTISE  ***********************/

.box_aside {
    float: left;
}
.box_cnt {
    padding: 20px 0 0 15px;
}
.box_cnt__no-flow {
    overflow: hidden;
}

/******************* FIN BLOC EXPERTISE  ***********************/

/******************* 4 BLOCS SECTION (Recheche & Développement, Veille Techno, etc.) ***********************/

@media(max-width: 768px) {
	.quatreblocs {
		margin-bottom: 20px;
	}	
}

/******************* FIN 4 BLOCS SECTION (Recheche & Développement, Veille Techno, etc.)******************/


/******************* REFERENCES SECTION  ***********************/

div.col-md-12.img-ref div.col-md-2 img{
    height: 110px !important;
}

/******************* FIN REFERENCES SECTION  ******************/

/******************* LOGO SECTION  ***********************/

.bandeau-logo{
	text-align: center;
}
.bandeau-logo figcaption{
	font-size: 14px;
    line-height: 20px;
    display: inline-block;
    margin-top: 20px;
    @media(max-width: 768px) {
		font-size: 14px;
	}
}

figure {
    margin: 1em 40px;
}

/******************* FIN LOGO SECTION  ***********************/

/****************  GOOGLE MAP RESPONSIVE  ********************/

.container-iframe{
	position: relative; 
  	overflow: hidden; 
	padding-top: 56.25%;
}
.responsive-iframe{
	position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  /*height: 100%;*/ 
  border: 0; 
}

/*************  GOOGLE MAP RESPONSIVE FIN *****************/


/**********************  BOUTONS  ***********************/

.btn1 {
  font: 300 18px/27px 'Roboto', sans-serif;
  color: @brand-primary;
  text-decoration: underline;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
  -o-transition: 0.3s ease all;
  transition: 0.3s ease all;
  display: inline-block;
  float: right;
  margin-right: 20px;
  margin-top: 10px;
}
.btn1:active {
  color: #0a588d;
}
.btn-accueil{
	font: 400 16px/31px 'Roboto', sans-serif;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: -0.4px;
    padding: 5px 20px 5px 20px;
    background: @brand-primary;
    display: inline-block;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

/********************** FIN  BOUTONS  ***********************/

/**********************  MARGIN ACUUEIL  ***********************/

.margin-accueil{
	margin-bottom: 10px;
}

/**********************  MARGIN ACCUEIL  ***********************/


/*==================  POLICES ACCUEIL  ======================*/
h3.etudes-travaux{
	font-size: 15px;
	text-transform:uppercase;
    text-align: left;
    @media(max-width: 768px) {
		font-size: 12px;
	}
}

h1 {
	span.accueil1 {
		font-size: 28px;
		font-weight: normal;
		color: @brand-primary;
		text-align: left;
		text-transform: uppercase;
		@media(max-width: 768px) {
			font-size: 24px;
		}
	}
}
h1 {
	span.accueil2 {
		text-transform: uppercase;
		font-size: 20px;
		color: @brand-primary;
			@media(max-width: 768px) {
			font-size: 16px;
		}
	}
}
h2.accueil1{
	font-size: 20px;
	text-transform: uppercase;
	color: @brand-primary;
	@media(max-width: 768px) {
		font-size: 20px;
	}
}
h2.accueil2{
	font-size: 15px;
	border-bottom: 1px solid @brand-primary;
	@media(max-width: 768px) {
		font-size: 12px;
	}
}

span.accueil2{
	font-size: 20px;
	color: @brand-primary;
	font-weight: 400;
	@media(max-width: 768px) {
		font-size: 16px;
	}
}

/*==================  FIN POLICES ACCUEIL ======================*/

/*========================================================
                     ACCUEIL  Page	END
=========================================================*/


/*========================================================
                     PRINCIPAUX CLIENTS  Page
=========================================================*/

.logo-clients{
	text-align: center;
	margin-bottom: 10px;
}
.logo-clients figcaption{
	font-size: 14px;
    line-height: 16px;
    display: inline-block;
    margin-top: 10px;
}

.logo-clients figure {
    margin: 0px;
}

/*========================================================
                    FIN PRINCIPAUX CLIENTS  Page
=========================================================*/

/*========================================================
                     REFERENCES  Page
=========================================================*/

.menu-ref {
	margin-bottom: 0px;
	background-color: #cbe0e8;
	padding: 10px;
	color: @brand-primary;
	text-align: right;
	label {
		margin-right: 0px;
	}
	input {
		margin-right: 0px;
	}
}
@media(max-width: 700px) {
	.menu-ref {
		label {
			font-size: 14px;
		}
	}
}	
.menu-ref-transversale {
	margin-bottom: 10px;
	background-color: #538ca2;
	padding: 10px;
	text-align: right;	
	form {
		font-size: 18px;
	}
	label {
		margin-right: 0px;
		color: white;
	}
	input {
		margin-right: 0px;
	}
}
@media(max-width: 700px) {
	.menu-ref-transversale {
		label {
			font-size: 14px;
		}
	}
}
#container-references {
	hr {
		border-top: thin solid #bbb;
	}
}

.reference {
	margin-top: 20px;
	h3 {
		line-height: 1.3em;
		margin-bottom: 0px;
	}
	h4 {
		margin-bottom: 15px;
	}
}

.table.table-striped {
	background-color: #e3e3e3;
}

table.ref-projet.table.table-striped td {
	padding: 3px 10px;
	line-height: 1.4em;
}

@media screen and (min-width: 630px) {
	.ref-projet td:nth-child(2n+1){
		font-style: italic;
	    width: 210px;
	}
}
@media screen and (max-width: 629px) {
	.ref-projet td:nth-child(2n+1){
		font-style: italic;
	    width: 120px;
	}
}
.ref-projet td:nth-child(2n){
	font-weight: normal;
}

.autres-images {
		display: block;
}

/*========================================================
                     FIN REFERENCES  Page
=========================================================*/

/*========================================================
                     HISTORIQUE  Page
=========================================================*/

div.historique {
	h2 {
		font-size: 25px;
	}
}

@media(min-width: 992px) {
	.hidden-2-columns {
		display: none;
	}
}

/*========================================================
                     FIN HISTORIQUE  Page
=========================================================*/


/*========================================================
                     QUALIFICATIONS  Page
=========================================================*/

/* The gallery overlay */
#galleryOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 100000;
  background-color: #222;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  display: none;
  -moz-transition: opacity 1s ease;
  -webkit-transition: opacity 1s ease;
  transition: opacity 1s ease;
}
/* This class will trigger the animation */
#galleryOverlay.visible {
  opacity: 1;
}
#gallerySlider {
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  white-space: nowrap;
  position: absolute;
  -moz-transition: left 0.4s ease;
  -webkit-transition: left 0.4s ease;
  transition: left 0.4s ease;
}
#gallerySlider .placeholder {
  background: url("../images/preloader.gif") no-repeat center center;
  height: 100%;
  line-height: 1px;
  text-align: center;
  width: 100%;
  display: inline-block;
}
/* The before element moves the
 * image halfway from the top */
#gallerySlider .placeholder:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}
#gallerySlider .placeholder .iframe-wrap,
#gallerySlider .placeholder img {
  display: inline-block;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
}
#gallerySlider .placeholder .iframe-wrap {
  width: 1280px;
}
#gallerySlider .placeholder .iframe-wrap .iframe {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
#gallerySlider .placeholder .iframe-wrap .iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#gallerySlider.rightSpring {
  -moz-animation: rightSpring 0.3s;
  -webkit-animation: rightSpring 0.3s;
}
#gallerySlider.leftSpring {
  -moz-animation: leftSpring 0.3s;
  -webkit-animation: leftSpring 0.3s;
}

/*========================================================
                     QUALIFICATIONS  Page	END
=========================================================*/
/*========================================================
                POLITIQUE CONFIDENTIALITE Page
=========================================================*/

.titre-article {
    background: @brand-primary;
    color: white;
    max-width: 100%;
    margin: 0px 0px 20px;
    padding: 5px 10px;
}
.t20 {
    font-size: 20px;
}

/*========================================================
            POLITIQUE CONFIDENTIALITE  Page	END
=========================================================*/


/*========================================================
                POLITIQUE COOKIES Page
=========================================================*/

ul.page-cookies {
    padding-inline-start: 40px;
    li {
        list-style-type: disc;
    }
}

/*========================================================
            POLITIQUE COOKIES  Page	END
=========================================================*/


/*========================================================
                     PLAN DU SITE Page
=========================================================*/

.map-items ul {
    margin: 0 0 9px 25px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 9px;
    margin-left: 25px;
    padding-left: 40px;
}
.map-items ul li {
	list-style-type: disc;
}
.map-items ul ul li {
	list-style-type: circle;
}

/*========================================================
                     PLAN DU SITE Page	END
=========================================================*/

/*================== PROJET EN COURS    ======================*/


table.ref-projet-en-cours.table.table-striped td {
	padding: 2px 10px;
	line-height: 1.1em;
}

@media screen and (min-width: 630px) {
	.ref-projet-en-cours td:nth-child(2n+1){
		font-style: italic;
	    width: 210px;
	}
}
@media screen and (max-width: 629px) {
	.ref-projet-en-cours td:nth-child(2n+1){
		font-style: italic;
	    width: 120px;
	}
}
.ref-projet-en-cours td:nth-child(2n){
	font-weight: normal;
}

.title {
	    background-color: #e3e3e3;
}
.value {
	    background-color: #e3e3e3;
	    @media(max-width: 767px) {
	    	background-color: #f9f9f9;
	    }
}

/*================== FIN PROJET EN COURS    ==================*/


/*==================  Stuck menu  ======================*/
.stuck_container:before,
.stuck_container:after {
  content: " ";
  display: table;
}
.stuck_container:after {
  clear: both;
}
.stuck_container:before,
.stuck_container:after {
  content: " ";
  display: table;
}
.stuck_container:after {
  clear: both;
}
.stuck_container:before,
.stuck_container:after {
  content: " ";
  display: table;
}
.stuck_container:after {
  clear: both;
}
.stuck_container:before,
.stuck_container:after {
  content: " ";
  display: table;
}
.stuck_container:after {
  clear: both;
}
.isStuck {
  z-index: 999;
  box-shadow: 0 1px 2px #bbb;
  background: rgba(255, 255, 255, 0.9);
  @media screen and (min-width: 992px) and (max-width: 1368px) {
	  padding-left: 10px !important;
	  padding-right: 10px !important;
  }
  @media screen and (min-width: 1368px) {
	  padding-left: 240px !important;
	  padding-right: 100px !important;
  }  
}
.isStuck .navbar-header {
  padding-bottom: 10px;
  padding-top: 5px;
  max-width: 260px;
}
.isStuck .navbar-header .navbar-brand {
  font-size: 35px;
}
@media (max-width: 1300px) {
  .isStuck .navbar-header {
    display: none;
  }
}
.isStuck .navbar-nav {
  padding-bottom: 10px;
  padding-top: 10px;
  float: right;
}
.isStuck .search-form_toggle {
  margin-top: 10px;
}
@media only screen and (max-width: 992px) {
  .isStuck {
    display: none !important;
  }
}
.isStuck .sf-menu ul {
  top: 70px;
}
.isStuck .sf-menu ul ul {
  top: 0;
}

/*================== FIN Stuck menu  ======================*/


/*==================     ToTop     ======================*/
.toTop {
  font-size: 37px!important;
  line-height: 37px;
  position: fixed;
  right: 40px;
  bottom: 40px;
  display: none;
  overflow: hidden;
  z-index: 20;
}
.toTop:before {
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
  -o-transition: 0.3s ease all;
  transition: 0.3s ease all;
  content: '\f0aa' !important;
  color: #4e6571!important;
}
.toTop:hover:before {
  color: #ef4023;
}
.toTop:hover {
	text-decoration: none;
}
@media only screen and (max-width: 1399px) {
  .toTop {
    display: none !important;
  }
}

/*==================  FIN  ToTop   ======================*/


/*==================   Thumbnail    ======================*/
.thumb {
  display: block;
  position: relative;
  overflow: hidden;
  z-index: 2;
}
@media (max-width: 991px) {
  .thumb {
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
  }
}
.thumb img {
  width: 100%;
  height: auto;
  -moz-transition: 0.5s all ease;
  -webkit-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
.thumb_overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  -moz-transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  color: #ffffff;
  background: transparent;
}
.thumb_overlay:before {
  content: '\f0c1';
  position: absolute;
  top: 48%;
  left: 4px;
  right: 0;
  font: 400 51px 'FontAwesome';
  line-height: 110px;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  height: 103px;
  width: 103px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #fff;
  background: rgba(255, 255, 255, 0.43);
  margin-left: auto;
  margin-right: auto;
}
.lt-ie9 .thumb_overlay:before {
  background: none;
  -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffffffff');
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffffffff');
  zoom: 1;
}
.lt-ie9 .thumb_overlay:before {
  display: none;
}
.thumb:hover .thumb_overlay {
  opacity: 1;
  background: rgba(0, 0, 0, 0.4);
}
.lt-ie9 .thumb:hover .thumb_overlay {
  background: none;
  -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff000000', endColorstr='#ff000000');
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff000000', endColorstr='#ff000000');
  zoom: 1;
}
.lt-ie9 .thumb:hover .thumb_overlay:before {
  display: block;
}
.thumb:hover img {
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

/*================== FIN  Tumbnail    ======================*/


/*==================  RD Parallax    ======================*/

.parallax {
  position: relative;
  overflow: hidden;
}
.parallax_image,
.parallax_pattern {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-position: center center;
  will-change: transform;
}
.parallax_image {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
.parallax_pattern {
  background-repeat: repeat;
}
.parallax_cnt {
  position: relative;
}
.parallax a.link {
  text-decoration: underline;
}

/*================== FIN RD Parallax    ======================*/


/*==================   RD Mobile Menu    =====================*/
.rd-mobilemenu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9999999;
  text-align: left;
  -moz-transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.rd-mobilemenu.active {
  right: 0;
}
.rd-mobilemenu_ul {
  position: fixed;
  top: -56px;
  left: 0;
  bottom: -56px;
  width: 240px;
  padding: 132px 0 76px;
  color: #666666;
  background: rgba(255, 255, 255, 0.97);
  font-size: 14px;
  line-height: 20px;
  overflow: auto;
  -moz-box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.1);
  -moz-transform: translateX(-240px);
  -ms-transform: translateX(-240px);
  -o-transform: translateX(-240px);
  -webkit-transform: translateX(-240px);
  transform: translateX(-240px);
  -moz-transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  -moz-transition: 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  -o-transition: 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  transition: 0.3s cubic-bezier(0.55, 0, 0.1, 1);
}
.lt-ie9 .rd-mobilemenu_ul {
  background: none;
  -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffffffff');
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffffffff');
  zoom: 1;
}
.rd-mobilemenu.active .rd-mobilemenu_ul {
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.rd-mobilemenu_ul li.active > a {
  background: @brand-primary;
  color: #ffffff;
}
.rd-mobilemenu_ul a {
  display: block;
  padding: 14px 25px 16px;
}
.rd-mobilemenu_ul a.active {
  background: #f5f5f5;
  color: #2d2d2d;
}
.rd-mobilemenu_ul a:hover {
  background: @bleu-turquoise-bet;
  color: #000000;
}
.rd-mobilemenu_ul a .rd-submenu-toggle {
  position: absolute;
  top: 50%;
  right: 11px;
  margin-top: -17.5px;
  width: 32px;
  height: 32px;
  font: 400 10px 'FontAwesome';
  line-height: 32px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #000000;
  -moz-transition: 0.5s all ease;
  -webkit-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  transition: 0.5s all ease;
  z-index: 1;
  background: rgba(0, 0, 0, 0.1);
}
.rd-mobilemenu_ul a .rd-submenu-toggle:after {
  content: '\f078';
}
.rd-mobilemenu_ul a .rd-submenu-toggle:hover {
  background: #ffffff;
  color: #666666;
}
.rd-mobilemenu_ul a.rd-with-ul {
  position: relative;
}
.rd-mobilemenu_ul a.rd-with-ul.active .rd-submenu-toggle {
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rd-mobilemenu_ul ul a {
  padding-left: 40px;
  border: none;
  padding-top: 14px;
  padding-bottom: 16px;
}
.rd-mobilemenu_ul ul ul {
  border: none;
  box-shadow: none;
  background: transparent;
  padding: 0;
  left: 0;
  top: 0;
  text-align: left;
  transform: none;
  margin-top: 0;
}
.rd-mobilemenu_ul ul ul:before,
.rd-mobilemenu_ul ul ul:after {
  display: none;
}
.rd-mobilemenu_ul ul ul li {
  border: none;
  box-shadow: none;
}
.rd-mobilemenu_ul ul ul a {
  padding: 0;
  margin: 0;
  padding-left: 60px;
  color: inherit;
  font-size: 14px;
  font-weight: 300;
  border: none;
  box-shadow: none;
}
.rd-mobilemenu_ul ul ul a.active {
  background: #f5f5f5;
  color: #2d2d2d;
}
.rd-mobilemenu_ul ul ul a:hover {
  background: @bleu-turquoise-bet;
  color: #000000;
}
.rd-mobilemenu_ul:after {
  content: '';
  display: block;
  height: 20px;
}
.rd-mobilepanel {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  background: #ffffff;
  color: #000000;
  -moz-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  z-index: 99999991;
}
.rd-mobilepanel_title {
  position: fixed;
  padding-left: 10px;
  top: 4px;
  left: 56px;
  right: 56px;
  color: #000000;
  font-size: 20px;
  line-height: 48px;
  letter-spacing: 0.2px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rd-mobilepanel_title small {
  display: none;
}
.rd-mobilepanel_logo {
	position: fixed;
	top: 10px;
	right: 5px;
	padding-right: 2px;
	width: 25%;
	height: auto;
}
.rd-mobilepanel_toggle {
  position: fixed;
  top: 4px;
  left: 8px;
  width: 48px;
  height: 48px;
  background-color: #f7f7f7;
  border-color: transparent;
  outline: none;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.rd-mobilepanel_toggle span {
  position: relative;
  display: block;
  left: 50%;
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.rd-mobilepanel_toggle span:after,
.rd-mobilepanel_toggle span:before {
  content: "";
  position: absolute;
  left: 0;
  top: -8px;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.rd-mobilepanel_toggle span:after {
  top: 8px;
}
.rd-mobilepanel_toggle span:after,
.rd-mobilepanel_toggle span:before,
.rd-mobilepanel_toggle span {
  width: 24px;
  height: 4px;
  background-color: #000000;
  backface-visibility: hidden;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.rd-mobilepanel_toggle span:before,
.rd-mobilepanel_toggle span:after {
  -webkit-transition-duration: 0.3s, 0.3s;
  transition-duration: 0.3s, 0.3s;
  -webkit-transition-delay: 0.3s, 0s;
  transition-delay: 0.3s, 0s;
  -webkit-transition-property: top, -webkit-transform;
  transition-property: top, transform;
}
.rd-mobilepanel_toggle.active span {
  transition: background .3s 0s ease;
  background: transparent;
}
.rd-mobilepanel_toggle.active span:before,
.rd-mobilepanel_toggle.active span:after {
  top: 0;
  -webkit-transition-delay: 0s, 0.3s;
  transition-delay: 0s, 0.3s;
}
.rd-mobilepanel_toggle.active span:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.rd-mobilepanel_toggle.active span:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
@media (max-width: 767px) {
  body {
    padding-top: 56px;
  }
  .rd-mobilemenu,
  .rd-mobilepanel {
    display: block;
  }
}
@media (max-width: 375px) {
  .rd-mobilepanel_title {
    font-size: 17px;
  }
}

/*================== FIN  RD Mobile Menu    =====================*/


/*========================================================
                     FOOTER  Section
=========================================================*/

footer {
    padding-top: 10px;
    padding-bottom: 10px;
    font: 300 15px/16px 'Roboto', sans-serif;
    text-transform: uppercase;
    background: #d1d2d4;
    background: #cbe0e8;
}
footer .inline-list {
    display: inline-block;
}
footer .inline-list li a {
    line-height:24px;
    text-align: center;
/*
    height: 68px;
    width: 68px;
    */
}
footer .inline-list li a:hover {
  background: none;
  color: #2d3a41;
    text-decoration: underline;
}
footer .inline-list li a:active {
  color: #8ba2af;
  border: 1px solid #8ba2af;
}
.inline-list li {
    float: left;
}
.inline-list li + li {
    margin-left: 15px;
}
footer a {
    text-transform: none;
}
div.heure-date form input{
    line-height: normal; 
}

#menu-footer {
	padding: 7px 0px;
}

.menu-footer {
	padding: 2px 2px;
}

.social{
    background: #fff;
    display: inline-block;
    color: @brand-primary;
    font-size: 18px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    vertical-align: middle;
    margin: 0 1px 8px;
    -webkit-border-radius: 17px; 
    -moz-border-radius: 17px; 
    border-radius: 17px;
	margin-right: 5px; 
}
a.social:visited{
	color: @brand-primary;
}
a.social:active{
	color: @brand-primary;
}
span a.social{
	color: @brand-primary;
}
span a.social:hover{
	color: #ef4023;
}



/*========================================================
                     FOOTER  Section	END
=========================================================*/


/*========================================================
                     ELEMENTS COMMUNS Section
=========================================================*/

/*******************  POLICES  *******************/

h1.page-interne{
	font-size: 24px;
    font-weight: normal;
    color: #fff;
    line-height: 1.5;
    text-transform: uppercase;
    @media(max-width: 768px){
    	display: none;
    }
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    margin: 0;
}

h1 {
	font-size: 28px;
}

h3 {
	font-size: 18px;
}

h4 {
	font-size: 16px;
}

h2, .h2 {
    font-size: 20px;
    line-height: 1.08333333;
    color: @brand-primary;
    text-transform: uppercase;
    @media(max-width: 768px) {
    	font-size: 15px;
    }
}

h2.page-interne {
  font-weight: normal;
  line-height: 1.3;
  margin-bottom: 15px;
}

strong {
  font-weight: 700;
}

.en-gras {
  font-weight: normal;
  line-height: 23px;
}

.fw-n {
    font-weight: 400;
    @media(max-width: 768px) {
    	font-size: 14px;
    }
}

.big {
  font-size: 16px;
  line-height: 24px;
  @media(max-width: 768px) {
		font-size: 14px;
	}
}

/******************* FIN POLICES  ***********************/

/*****************  LOGO BET HAUSS  ******************/

.brand_img{
	@media(max-width: 767px){
		display: none;
	}
}

/***************  LOGO BET HAUSS FIN ***************/


/*******************  Body  ***********************/
body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.2;
    color: #2d3a41;
    /*background-color: #d1d2d4;*/
    -webkit-text-size-adjust: none;
    
    /*@media(min-width: 1400px){
		background: url("../images/primary-bg.jpg") repeat;
	}*/
	
	
	/*@media(max-height: 861px){
		background: url("../images/primary-bg.jpg") repeat;
		
		.mentions-legales footer, .vie-privee footer, .politique-cookies footer{
			position: absolute;
	    	bottom: 62px;
	    	width: 100%;
		}

		.mentions-legales .bg-secondary-2, .vie-privee .bg-secondary-2, .politique-cookies .bg-secondary-2{
				position: absolute;
		    	bottom: 0;
		    	width: 100%;
		}
	}*/
	
}

@media(min-height: 361px){
	.vie-privee, .politique-cookies{
		background: url("../images/primary-bg.jpg") repeat;
		
		footer{
			position: absolute;
    		bottom: 62px;
    		width: 100%;
		}
		.bg-secondary-2{
			position: absolute;
	    	bottom: 0;
	    	width: 100%;
		}
	}
	
	@media(min-width: 1400px){
		.mentions-legales{
			background: url("../images/primary-bg.jpg") repeat;
			
			footer{
			position: absolute;
    		bottom: 62px;
    		width: 100%;
		}
		.bg-secondary-2{
			position: absolute;
	    	bottom: 0;
	    	width: 100%;
		}
		}
	}
	
}

/*******************  Fin Body  ***********************/


/*******************  HTML  ***********************/
html {
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*******************  Fin HTML  ***********************/


/*******************  LISTE  ***********************/
ul, ol {
    padding: 0;
    list-style: none;
    margin: 0;
}
ul li {
	list-style-type: none;
}
ul li a, ol li a {
    padding: 0;
    list-style: none;
    margin: 0;
    @media(max-width: 768px) {
    	font-size: 14px;
    }
}

/*******************  Fin LISTE  ***********************/

/*******************  LIENS  ***********************/

a {
    font: inherit;
    color: inherit;
    text-decoration: none;
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}
a:hover {
	color: #ef4023;
}

/*******************  Fin LIENS  ***********************/

/*******************  IMAGES  ***********************/

img {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}


.img-reference {
    width: 100%;
    border-bottom: thin solid white;
}

/*******************  Fin IMAGES  ***********************/


.box + .big {
  margin-top: 24px;
}

* + .btn1 {
  margin-top: 15px;
}

/*.row + .row {
    margin-top: 41px;
}
*/

@media(max-width: 991px) {
	.row .col-sm-12 + * {
	    margin-top: 30px;
	}
}

.row-3blocs .col-sm-6 {
    margin-top: 30px;
    margin-bottom: 30px;
}

@media (min-width: 768px) {
  div.container {
    padding-left: 0;
    padding-right: 0;
  }
}

.well {
    padding-top: 35px;
    padding-bottom: 35px;
    margin-bottom: 0px;
    border: none;
    background-color: white;
    -webkit-box-shadow: none;
}

.border {
    border-bottom: 1px solid #bfc0c3;
    border-top: 1px solid #bfc0c3;
}

.bg-light {
    background: #ffffff url("../images/primary-bg.jpg") repeat;
}

.bg-secondary-2 {
    background: @brand-primary;
    color: #fff;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block;
}

p {
    line-height: 1.5;
    margin: 0;
    @media(max-width: 768px) {
    	font-size: 14px;
    }
}

.custom-wrapper .custom-box {
    padding-top: 35px;
    padding-bottom: 10px;
/*    width: 69%; */
}


/*******************  Marked List  ***********************/
.marked-list li {
  position: relative;
  padding: 0 0 5px 25px;
  border-bottom: 1px solid #d5d6d8;
}
.marked-list li a:before {
  content: '\f18e';
  position: absolute;
  left: 0;
  top: 0;
  font-weight: 400;
  font-size: 17px;
  font-family: 'FontAwesome';
  color: #2d3a41;
  -moz-transition: 0.4s ease all;
  -webkit-transition: 0.4s ease all;
  -o-transition: 0.4s ease all;
  transition: 0.4s ease all;
}
.marked-list li a:hover {
  color: #ef4023;
  text-decoration: none;
}
.marked-list li a:hover:before {
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  color: #ef4023;
}
.marked-list li + li {
  margin-top: 3px;
}

/******************* FIN Marked List  ***********************/


/******************* HORLOGE  ***********************/
span.heure {
	border: none;
    padding-left: 15px;
    font-size: 28px;
    font-weight: bold;
    width: -webkit-fill-available;
	@media(max-width: 768px){
		font-size: 20px;
		padding-left: 10px;
	}
}
input.date {
	border: none;
    padding-left: 15px;
    font-weight: bold;
    width: -webkit-fill-available;
	@media(max-width: 768px){
		font-size: 9px;
		padding-left: 5px;
	}
}

/******************* FIN HORLOGE  ***********************/

/******************* CONTACT  ***********************/

adress.contact {
	@media(max-width: 768px) {
		font-size: 14px;
	}
}
span.contact {
	@media(max-width: 768px) {
		font-size: 14px;
	}
}

/******************* FIN CONTACT  ***********************/


/*========================================================
                     ELEMENTS COMMUNS  Section		END
=========================================================*/